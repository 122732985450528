/*---- 17. Sidebar style  ------*/

.sidebar-style {
  @media #{$lg-layout} {
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$md-layout} {
    &.mr-30 {
      margin-right: 0;
    }
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    &.mr-30 {
      margin-right: 0;
    }
    margin-top: 30px;
  }
  @media #{$lg-layout} {
    &.ml-30 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-30 {
      margin-left: 0;
    }
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    &.ml-30 {
      margin-left: 0;
    }
    margin-top: 30px;
  }
}

.sidebar-widget {
  h4.pro-sidebar-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin: 0;
  }
  .sidebar-widget-list {
    ul {
      li {
        align-items: center;
        position: relative;
        padding: 0px 0 15px;
        &:last-child {
          padding: 0px 0 0px;
        }
        .sidebar-widget-list-left {
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            z-index: 999;
            width: auto;
            &:checked ~ .checkmark {
              background-color: salmon;
              border: 2px solid salmon;
            }
            &:checked ~ .checkmark::after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: #fff;
            border: 2px solid #888888;
            border-radius: 3px;
            &::after {
              content: "";
              position: absolute;
              display: none;
            }
            &::after {
              left: 3px;
              top: 0px;
              width: 5px;
              height: 8px;
              border: solid white;
              border-top-width: medium;
              border-right-width: medium;
              border-bottom-width: medium;
              border-left-width: medium;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
        a,
        button {
          display: block;
          background: none;
          padding: 0;
          padding-left: 30px;
          border: none;
          width: 100%;
          text-align: left;
          position: relative;
          text-transform: capitalize;
          span {
            color: #9a9a9a;
            font-weight: 500;
            background-color: #eeeeee;
            width: 30px;
            height: 20px;
            display: inline-block;
            text-align: center;
            line-height: 20px;
            border-radius: 50px;
            transition: all 0.3s ease 0s;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
          &:hover,
          &.active {
            span {
              color: #fff;
              background-color: $theme-color;
            }
            & > .checkmark {
              background-color: $theme-color;
              border-color: $theme-color;
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }

    &--blog {
      ul {
        li {
          .sidebar-widget-list-left {
            .checkmark {
              top: 5px;
            }
          }
        }
      }
    }
  }
  .sidebar-widget-tag {
    ul {
      li {
        display: inline-block;
        margin: 0 10px 15px 0;
        @media #{$lg-layout} {
          margin: 0 5px 15px 0;
        }
        a,
        button {
          color: #424242;
          display: inline-block;
          background: none;
          border: none;
          background-color: #f6f6f6;
          padding: 9px 17px 12px;
          text-transform: capitalize;
          @media #{$lg-layout} {
            padding: 9px 13px 12px;
          }
          line-height: 1;
          border-radius: 50px;
          &:hover,
          &.active {
            background-color: $theme-color;
            color: #fff;
          }
        }
      }
    }
  }
}

.pro-sidebar-search {
  .pro-sidebar-search-form {
    position: relative;
    input {
      background: transparent none repeat scroll 0 0;
      border: 1px solid #e6e6e6;
      color: #000;
      font-size: 14px;
      height: 43px;
      padding: 2px 55px 2px 18px;
    }
    button {
      background: transparent none repeat scroll 0 0;
      border-color: #a1a5aa;
      border-image: none;
      border-style: none none none solid;
      border-width: medium medium medium 1px;
      padding: 0 15px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #000;
      transition: all 0.3s ease 0s;
      font-size: 20px;
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.single-sidebar-blog {
  border-bottom: 1px solid #dee0e4;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0;
  padding: 0 0 10px;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
  .sidebar-blog-img {
    flex: 0 0 0px;
    margin: 0 20px 0 0;
    @media #{$lg-layout} {
      margin: 0 8px 0 0;
    }
    img {
      width: 100%;
    }
  }
  .sidebar-blog-content {
    span {
      color: #333;
      display: block;
      font-size: 11px;
      letter-spacing: 1px;
      font-weight: 300;
      line-height: 1;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      margin: 5px 0 0;
    }
  }
}
